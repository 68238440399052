import React from "react";
import { Classes, BS } from "./Bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faNetworkWired, faSearch } from "@fortawesome/free-solid-svg-icons";

function App() {
  const [name, setName] = React.useState<string>("");
  const [type, setType] = React.useState<string>("A");
  const [result, setResult] = React.useState<string>("");

  async function search() {
    const url: string = `https://cloudflare-dns.com/dns-query?name=${name}&type=${type}&do=1&cd=1`;

    const headers: HeadersInit = {
      accept: "application/dns-json"
    };

    const init: RequestInit = { headers };

    const response = await window.fetch(url, init);

    const json = await response.json();

    const jsonString = JSON.stringify({ date: new Date(), query: `${name} IN ${type}`, answer: json }, null, 2);

    setResult(jsonString);
  }

  return (
    <div className={BS.container_fluid}>
      <div className={Classes(BS.input_group, BS.mt_3)}>
        <span className={BS.input_group_text}>
          <FontAwesomeIcon icon={faGlobe} />
        </span>
        <input
          className={Classes(BS.form_control)}
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </div>
      <div className={Classes(BS.input_group, BS.mt_3)}>
        <span className={BS.input_group_text}>
          <FontAwesomeIcon icon={faNetworkWired} />
        </span>
        <select
          className={Classes(BS.form_select)}
          onChange={(event) => setType(event.target.value)}
          value={type}>
          <optgroup label="common">
            <option value="A">A</option>
            <option value="AAAA">AAAA</option>
            <option value="CNAME">CNAME</option>
            <option value="MX">MX</option>
            <option value="NS">NS</option>
            <option value="PTR">PTR</option>
            <option value="SOA">SOA</option>
            <option value="SRV">SRV</option>
            <option value="TXT">TXT</option>
          </optgroup>
          <optgroup label="uncommon">
            <option value="AFSDB">AFSDB</option>
            <option value="APL">APL</option>
            <option value="CAA">CAA</option>
            <option value="DNSKEY">DNSKEY</option>
            <option value="CDNSKEY">CDNSKEY</option>
            <option value="CERT">CERT</option>
            <option value="DCHID">DCHID</option>
            <option value="DNAME">DNAME</option>
            <option value="HIP">HIP</option>
            <option value="IPSECKEY">IPSECKEY</option>
            <option value="LOC">LOC</option>
            <option value="NAPTR">NAPTR</option>
            <option value="NSEC">NSEC</option>
            <option value="RRSIG">RRSIG</option>
            <option value="RP">RP</option>
            <option value="SSHFP">SSHFP</option>
          </optgroup>
        </select>
      </div>
      <div className={Classes(BS.input_group, BS.mt_3)}>
        <button
          className={Classes(BS.btn, BS.btn_success, BS.w_100)}
          onClick={() => search()}
          type="button">
          <FontAwesomeIcon icon={faSearch} /> Search
        </button>
      </div>
      <div className={BS.input_group}>
        <textarea
          style={{ minHeight: "75vh" }}
          className={Classes(BS.form_control, BS.mt_3)}
          value={result}
          readOnly={true} />
      </div>
    </div>
  );
}

export default App;
